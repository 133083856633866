import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import SideBar from './sidebar/SideBar';
import Header from './header/Header';
import Footer from './footer/Footer';
import ProjectSection from './project-section/ProjectSection';
import Timeline from './timeline/Timeline';
import Blog from './blog/Blog';
import Picross from './picross/Picross';
import PicrossGame from './picross/PicrossGame';
import BlogEntry from './blog/BlogEntry';
import './index.css';
import {BrowserRouter, Route} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route path={"/"} exact={true}>
        <SideBar />

          <Header title="Welcome!" blurb={["My name is Brian Hughes and I am a Junior Site Reliability Engineer at DonorDrive.", "Please see below for my experience and contact information.", "Thank you, and I look forward to working with you!"]}
          />
          <Timeline timelineitems={[
              {
                time: "September 2016",
                title: "Enrolled in Northeastern University",
                desc:[
                  "Began studying Computer Science at Northeastern's Khoury College of Computer Science",
                  "Joined Northeastern's student radio station: WRBB 104.9 FM"]
              },
              {
                time: "July 2018 - December 2018",
                title: "Data Analyst Co-op",
                company: "ClearEdge Partners",
                desc:
                [
                  "Consulted and advised Fortune 500 clients on their enterprise software and hardware purchases",
                  "Analyzed client interactions and deal leverage, and created presentations on behalf of account managers explaining these trends",
                  "Independently created weekly presentations for the CEO and leadership team to report company performance",
                  "Built numerous models to support the Storage Analytics Team"
                ]
              },
              {
                time: "July 2019 - December 2019",
                title: "DevOps Co-op",
                company: "Wanderu",
                desc:
                [
                  "Updated Icinga and AWS SNS monitoring systems to improve site reliability and application health",
                  "Streamlined development processes by implementing pilot and production auto-deployments through Wanderu’s in-house deployment application",
                  "Created nginx rewrites and updated configurations to retain or redistribute link equity for search engines",
                  "Simplified AWS infrastructure and reduced cost by shutting down depreciated application servers"
                  ]
              },
              {
                time: "July 2020 - April 2021",
                title: "DevOps Co-op",
                company: "PowerAdvocate",
                desc:
                [
                  "Point of contact between Data Science and DevOps teams, facilitating collaboration on joint projects",
                  "Maintain base Docker images for Java and Python applications"]
              },
              {
                time: "May 2021",
                title: "Graduated Northeastern University",
                desc:
                [
                  "Graduated with a B.S. in Computer Science and a concentration in Systems"]
              },
              {
                time: "June 2021",
                title: "DevOps Engineer I",
                company: "PowerAdvocate",
                desc:
                [
                  "Coordinated releases for PowerAdvocate products, working with software engineers and product managers",
                  "Created new AWS resources using Cloudformation Templates to support new products and features",
                  "Supervised two DevOps co-ops and facilitated their onboarding onto the team",
                  "Expanded and updated the DevOps documentation for onboarding, releases, and OS patching"]
              },
              {
                time: "August 2022",
                title: "Junior Site Reliability Engineer ",
                company: "DonorDrive",
                desc:
                [
                  "Started as a Junior Site Reliability Engineer at DonorDrive."]
              }
            ]}/>
          <ProjectSection projectItems={[
              {
                title: "Deployment Automator",
                desc:[
                  "Improved upon an existing deployment automation tool that locks and deploys applications through Slack commands",
                  "Used Jenkins and Bitbucket API’s to deploy application branches to specific environments",
                  "Developed a system for automated deployments to pilot and production environments"
                ]
              },
              {
                title: "IT Automation Scripts",
                desc:
                [
                  "Improved monitoring and on-call experience by updating an auto-recovery script to acknowledge Icinga issues",
                  "Streamlined IT auditing by adding scripts to verify Google and collapsed Directory accounts"]
              },
              {
                title: "Personal Website",
                desc:
                [
                  "Created a single page react app bootstrapped using create-react-app",
                  "Deployed through AWS utilizing GitLab CI"],
                link: "https://gitlab.com/hughesbrian/personal-website"
              },
              {
                title: "ECS Cluster Management",
                desc:
                [
                  "Implented 0 down-time OS Updates",
                  "Led a team of 3 to update ECS Clusters to use Launch Templates instead of Launch Configurations, enabling proper tagging of all resources",
                  "Led the implementation of automatic installation of packages and agents on ECS instances through UserData"]
              }
            ]}/>
          <Footer/>
                </Route>
        <Route path="/blog/" exact={true}>
          <Blog/>
        </Route>
        <Route path="/artwork/" exact={true}>
          <Picross/>
        </Route>
        <Route path="/blog/:id" exact={true}>
        <BlogEntry/>
        </Route>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
