import React from 'react';
import photo from '../static/Header-Img.jpg';
import './Header.css';

function Header(props) {
  var width = document.documentElement.clientWidth;
  const blurbContents = [];
  for(var i = 0; i < props.blurb.length; i++) {
    blurbContents.push(<p>{props.blurb[i]}</p>)
  }
  const temp = [<div className="Header-content">
              <img className="Header-Img" src={photo} alt="decorative jellyfish header image"></img>
              <h1>{props.title}</h1>
              {blurbContents}
              </div>]
  if (width < 600){
      return (
        <header className="Header mobile" id="Header">
          {temp}
        </header>
      );
  }
  else if (width < 800){
    return (
      <header className="Header collapsed" id="Header">
        {temp}
      </header>
    );
  }
  else{
    return (
      <header className="Header" id="Header">
        {temp}
      </header>
    );
  }

}

export default Header;