import React from 'react';
import photo from '../static/BrianHughes2.jpg';
import './SideBar.css';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';


class SideBar extends React.Component{
  constructor(props){
    super(props);
    var width = document.documentElement.clientWidth
    var collapsed = false
    var removed = false
    if(width < 800){
      collapsed = true
    }
    if(width < 600){
      removed = true
    }
    this.state = {
      collapsed: collapsed,
      removed: removed,
      prev_width: width
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.checkSize.bind(this))
    this.checkSize();
  }
  render(){
    const name = "brianehughes"
    const organization = "protonmail"
    const domain = "com"
    const temp = [
                <a className="fa fa-bars hamburger-menu"  aria-label="collapse or open sidebar" onClick={() => this.ChangeSize(!this.state.collapsed)}>
                  <i aria-hidden="true"></i>
                </a>,
                <h2>Brian Hughes</h2>,
                <img className="SideBar-Img" src={photo} alt="" width="200" height="200"></img>,
                <nav className="SideBar-Nav">
                <HashLink smooth to="#Header">About</HashLink>
                <HashLink smooth to="#TimelinePage">Experience</HashLink>
                <HashLink smooth to="#ProjectSection">Projects</HashLink>
                <Link to="/artwork">Artwork Project</Link>
                <Link to="/blog">Blog</Link>
                </nav>,
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>,
                <div className="Links">
                <a href="https://www.linkedin.com/in/brian-hughes-88085316a"aria-label="linked-in profile link">
                  <i aria-hidden="true" className="fa fa-linkedin Sidebar-Link"></i>
                </a>
                <a href="https://gitlab.com/hughesbrian" aria-label="gitlab profile link">
                  <i aria-hidden="true" className="fa fa-gitlab Sidebar-Link"></i>
                </a>
                <a href={"mailto:" + name + "@" + organization + "." + domain} aria-label="email link">
                  <i aria-hidden="true" className="fa fa-envelope Sidebar-Link"></i>
                </a>
                </div>];
    var width = document.documentElement.clientWidth;
    if (width < 600){
      return (
        <aside className="SideBar mobile" id="SideBar">
          {temp}
        </aside>
      );
    }
    else if (width < 800){
      return (
        <aside className="SideBar collapsed" id="SideBar">
          {temp}
        </aside>
      );
    }
    else{
      return (
        <aside className="SideBar" id="SideBar">
          {temp}
        </aside>
      );
    }
  }

  //Changes size of the various html elements to either hide, collapse, or enlarge the sidebar
  ChangeSize(shrink) {
    var element_ids = ["SideBar", "ProjectSection", "Header", "TimelinePage", "Footer"]
    if((shrink && this.state.collapsed) || (!shrink && this.state.removed)){
      for(var i = 0; i < element_ids.length; i++){
        var classlist = document.getElementById(element_ids[i]).classList
        if(!document.getElementById(element_ids[i])){
          continue
        }
        console.log("toggling mobile")
        classlist.toggle('mobile')
        this.setState({
          removed: !this.state.removed,
        })
      }
    }
    else if((shrink && !this.state.collapsed) || (!shrink && this.state.collapsed)){
      for(var i = 0; i < element_ids.length; i++){
        var classlist = document.getElementById(element_ids[i]).classList
        if(!document.getElementById(element_ids[i])){
          continue
        }
        console.log("toggling collapsed")
        classlist.toggle('collapsed')
        this.setState({
          collapsed: !this.state.collapsed,
        })
      }
    }
  }
  //checks size of the window, collapses or enlarges accordingly
  checkSize() {
    var width = document.documentElement.clientWidth
    if (width > 800  && this.state.prev_width <= 800){
      this.ChangeSize(false)
    }
    else if (width < 800 && this.state.prev_width >= 800){
      this.ChangeSize(true)
    }
    else if (width < 600 && this.state.prev_width >= 600){
      this.ChangeSize(true)
    }
    else if (width > 600 && this.state.prev_width <= 600){
      this.ChangeSize(false)
    }
    this.setState({
      prev_width: width
    })
  }
}
export default SideBar;
