import React from 'react';
import './Timeline.css';
//Referenced code from https://stackoverflow.com/questions/21916615/horizontal-timeline-in-pure-css

function Timeline(props) {
    const leftTimelineItems = [];
    const rightTimelineItems = [];
    var i;
    for(i =0; i < props.timelineitems.length; i++){
      const temp = [];
      var j
      if (i % 2 === 1){
        for(j =0; j < props.timelineitems[i].desc.length; j++){
          temp.push(<li>{props.timelineitems[i].desc[j]}</li>)
        }
        rightTimelineItems.push(<div className="EntryRight">
            <h3>{props.timelineitems[i].time}</h3>
            <h4>{props.timelineitems[i].title}</h4>
            <h4>{props.timelineitems[i].company}</h4>
            <ul>
              {temp}
            </ul>
          </div>)
      }
      else {
        for(j =0; j < props.timelineitems[i].desc.length; j++){
          temp.push(<li>{props.timelineitems[i].desc[j]}</li>)
        }
        if(i === (props.timelineitems.length -1)){
          leftTimelineItems.push(<div className="EntryLeft EntryLeftLast">
              <h3>{props.timelineitems[i].time}</h3>
              <h4>{props.timelineitems[i].title}</h4>
              <h4>{props.timelineitems[i].company}</h4>
              <ul>
                {temp}
              </ul>
            </div>)
        }
        else {
          leftTimelineItems.push(<div className="EntryLeft">
            <h3>{props.timelineitems[i].time}</h3>
            <h4>{props.timelineitems[i].title}</h4>
            <h4>{props.timelineitems[i].company}</h4>
            <ul>
              {temp}
            </ul>
          </div>)
        }
      }
    }
    var width = document.documentElement.clientWidth;
    var temp = [
      <h1>Timeline</h1>,
      <div  className="Timeline">
        <div className="TimelineSide">
        {leftTimelineItems}
        </div>
        <div class="Bar"></div>
        <div class="TimelineSide">
        {rightTimelineItems}
        </div>
      </div>]
    if (width < 600){
      return (
        <section className="TimelinePage mobile" id="TimelinePage">
          {temp}
        </section>);
    }
    else if (width < 800){
      return (
        <section className="TimelinePage collapsed" id="TimelinePage">
          {temp}
        </section>);
    }
    else {
      return (
        <section className="TimelinePage" id="TimelinePage">
          {temp}
        </section>);
    }
}

export default Timeline;