import React from 'react';
import './ProjectSection.css';

function ProjectSection(props) {
	var width = document.documentElement.clientWidth;
	const result = [];
    var i;
    for(i =0; i < props.projectItems.length; i++){
		const temp = [];
		var j
		for(j =0; j < props.projectItems[i].desc.length; j++){
			temp.push(<li>{props.projectItems[i].desc[j]}</li>)
		}
		if(props.projectItems[i].hasOwnProperty("link")){
			temp.push(<li>See source code <a className="codeLink" href={props.projectItems[i].link}>here</a></li>)
		}
		result.push(<div className="ExperienceCard">
			<h2>{props.projectItems[i].title}</h2>
			<ul className="ExperienceCard-ulist">
			{temp}
			</ul>
			</div>)
    }
	if (width < 600){
		return (
		<section className="ProjectSection mobile" id="ProjectSection">
			<h1>Projects</h1>
			<div className="ExperienceCards">
			{result}
			</div>
		</section>
		);
	}
	else if (width < 800){
		return (
		<section className="ProjectSection collapsed" id="ProjectSection">
			<h1>Projects</h1>
			<div className="ExperienceCards">
			{result}
			</div>
		</section>
		);
	}
	else {
		return (
			<section className="ProjectSection" id="ProjectSection">
			<h1>Projects</h1>
			<div className="ExperienceCards">
			{result}
			</div>
		</section>
		);
	}
}

export default ProjectSection;