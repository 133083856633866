import React, { useEffect, useState } from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import topPhoto from '../static/Header-Img.jpg';
import bottomPhoto from '../static/Footer-Img.jpg';
import localposts from'./posts.json';


function Blog() {
    var width = document.documentElement.clientWidth;
	const result = [];
	const [posts, setPosts] = useState([]);

	useEffect(() => {
		getPost();
  	}, [posts]);

	const getPost = async () => {
		setPosts(localposts);
	}
	result.push(<div>{posts.map(post => <Link to={"/blog/" + post.id}><div className="BlogCard">
	<h2>{post.title}</h2>
	{post.blurb}
	</div></Link>)}</div>)
	if (width < 800){
		return (
		<section className="BlogSection" id="BlogSection">
			<h1>Blog</h1>
            <img className="Header-Img-Blog" src={topPhoto} alt="decorative jellyfish header image"></img>
			<h3>Hi, my name is Brian Hughes and welcome to my blog! 
			<br></br>If you'd like to see my personal website please click <Link className="codeLink" to={"/"}>here.</Link></h3>
			<div className="BlogCards">
			{result}
			</div>
            <img className="Footer-Img-Blog" src={bottomPhoto} alt="decorative jellyfish footer image"></img>
		</section>
		);
	}
	else {
		return (
			<section className="BlogSection" id="BlogSection">
			<h1>Blog</h1>
            <img className="Header-Img-Blog" src={topPhoto} alt="decorative jellyfish header image"></img>
			<h3>Hi, my name is Brian Hughes and welcome to my blog! 
			<br></br>If you'd like to see my personal website please click <Link className="codeLink" to={"/"}>here.</Link></h3>
			<div className="BlogCards">
			{result}
			</div>
            <img className="Footer-Img-Blog" src={bottomPhoto} alt="decorative jellyfish footer image"></img>
		</section>
		);
	}
}

export default Blog;